import React, { useState, useEffect } from "react";

import Home from "./pages/Home";
import Skill from "./pages/Skillset";
import Project from "./pages/Projects.jsx";
import Contact from "./pages/Contact";

import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Preloader from "./components/PreLoader";
import Particle from "./components/Particle";

import "./App.css";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route, Navigate, redirect } from "react-router-dom";

const AR = () => {
  return redirect("https://www.youtube.com");
}

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Preloader load={load} />
              <div className="App" id={load ? "no-scroll" : "scroll"}>
                
                <Navbar />
                <Particle />
                <Home />
                <Skill />
                <Project />
                <Contact />
                <Footer />
              </div>
            </>
          }
        />
        <Route path="/ar" element={AR} />
        <Route path="*" element={ <Navigate to="/" replace={true} /> } />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
