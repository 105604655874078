import caverush from "../assets/projects/caverush.png";
import mathsandarchery from "../assets/projects/mathsandarchery.png";
import findmydegree from "../assets/projects/findmydegree.png";
import bouncer from "../assets/projects/bouncer.png";
import spaceadventure from "../assets/projects/spaceadventure.png";

const projectsDetails = [
    {
      key: 4,
      imgPath: caverush,
      title: "Cave Rush",
      description:
        "Cave Rush offers an exhilarating adventure where players navigate tricky traps, dodge spike balls and roll, jump, and bounce their way to safety inside the cave.",
      demoLink:
        "https://play.google.com/store/apps/details?id=com.slaip.caverush",
      ghLink: "",
      tech: "games",
      skills: "Unity | C#",
    },
    {
      key: 3,
      imgPath: spaceadventure,
      title: "Space Adventure",
      description:
        "Space Adventure is a game where players control a space ship, tasked with avoiding collisions with asteroids to prolong their survival.",
      demoLink: "",
      ghLink: "https://github.com/anuhasip/Space-Adventure",
      tech: "games",
      skills: "Unity | C#",
    },
    {
      key: 2,
      imgPath: findmydegree,
      title: "Find My Degree",
      description:
        "This system allows you to find a degree to your results according to the previous year's cutoff marks in specific district in Sri Lanka.",
      demoLink: "https://findmydegree.lk",
      ghLink: "",
      tech: "web",
      skills: "PHP | Javascript | MySQL",
    },
    {
      key: 1,
      imgPath: mathsandarchery,
      title: "Maths and Archery",
      description:
        "Combine the thrill of archery with fundamental arithmetic skills as players aim at targets with math answers, solving math problems to score points.",
      demoLink:
        "https://play.google.com/store/apps/details?id=com.slaip.mathsandarchery",
      ghLink: "",
      tech: "games",
      skills: "Unity | C#",
    },
    {
      key: 0,
      imgPath: bouncer,
      title: "Bounce Ball 1.0",
      description:
        "Bounce Ball 1.0 is one of the most interesting bouncing ball game with simple control and challenging adventures.",
      demoLink:
        "https://play.google.com/store/apps/details?id=com.slaip.bouncer",
      ghLink: "",
      tech: "games",
      skills: "Unity | C#",
    },
  ];

  export default projectsDetails;