import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiReact
} from "react-icons/di";

import { 
  FaBootstrap
} from "react-icons/fa";

import { SiNextdotjs, SiTailwindcss } from "react-icons/si";

const Frontend = () => {
  return (
  <>
    
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      
      <Col xs={4} md={2} className="tech-icons">
        <FaBootstrap />
        <span>Bootstrap</span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiTailwindcss />
        <span>Tailwind</span>
      </Col>
      
      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
        <span>React JS</span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiNextdotjs />
        <span>Next JS</span>
      </Col>
      
    </Row>
  </>
  );
}

export default Frontend;
