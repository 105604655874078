import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiNodejs,
} from "react-icons/di";
import {
  SiExpress,
  SiFastapi,
} from "react-icons/si";


const Backend = () => {
  return (
  <>
    
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      
      <Col xs={4} md={2} className="tech-icons">
        <DiNodejs />
        <span>Node JS</span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiExpress />
        <span>Express JS</span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFastapi />
        <span>FastAPI</span>
      </Col>
    </Row>
  </>
  );
}

export default Backend;
