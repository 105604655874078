import React from "react";
import { Col, Row } from "react-bootstrap";

import {

  SiUnity,
  SiCsharp
} from "react-icons/si";


const GameDev = () => {
  return (
  <>
    
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      
      <Col xs={4} md={2} className="tech-icons">
        <SiUnity />
        <span>Unity</span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiCsharp />
        <span>C#</span>
      </Col>
    </Row>
  </>
  );
}

export default GameDev;
