import { useCallback, useEffect, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
// import { loadAll } from "@/tsparticles/all"; // if you are going to use `loadAll`, install the "@tsparticles/all" package too.
// import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
// import { loadBasic } from "@tsparticles/basic"; // if you are going to use `loadBasic`, install the "@tsparticles/basic" package too.

const Particle = () => {
  const [init, setInit] = useState(false);

  // this should be run only once per application lifetime
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      //await loadAll(engine);
      //await loadFull(engine);
      await loadSlim(engine);
      //await loadBasic(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  return (
    <>
      {init && (
        <Particles
          id="tsparticles"
          particlesLoaded={particlesLoaded}
          options={{
            fullScreen: { enable: true },
            particles: {
              number: {
                value: 160, // Number of glowing stars (circles)
                density: {
                  enable: true,
                  value_area: 800, // Controls how packed the stars are
                },
              },
              color: {
                value: "#ffffff", // White color for stars
              },
              shape: {
                type: "circle", // Circles as stars
              },
              size: {
                value: { min: 0.2, max: 1 }, // Random sizes for depth effect
                anim: {
                  enable: true, // Enable size animation
                  speed: 1, // Speed of size animation
                  size_min: 0.1, // Minimum size during animation
                  startValue: "min", // Start animation from the minimum size
                  sync: false, // Animation happens asynchronously for each particle
                },
              },
              opacity: {
                value: 0.8, // Starting opacity
                anim: {
                  enable: true, // Animate opacity for glow effect
                  speed: 1, // Speed of opacity animation
                  opacity_min: 0.01, // Minimum opacity for the glow
                  sync: false, // Opacity animation is not synchronized between particles
                },
              },
              move: {
                enable: true,
                speed: 0.2, // Slow movement to make stars look like they're floating
                direction: "none", // Random direction
                random: true,
                straight: false,
                outModes: {
                  default: "out", // Particles regenerate when they leave the canvas
                },
              },
              links: {
                enable: false, // No lines between particles
              },
            },
            interactivity: {
              events: {
                onHover: {
                  enable: false, // Disable hover effects
                },
                onClick: {
                  enable: false, // Disable click effects
                },
              },
            },
            retina_detect: true,
          }}
        />
      )}
    </>
  );
};

export default Particle;
