import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiJava
} from "react-icons/di";
import {
  SiPython,
  SiTypescript,
  SiJavascript,
  SiCsharp
} from "react-icons/si";


const Program = () => {
  return (
  <>
    
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
    <Col xs={4} md={2} className="tech-icons">
        <DiJava />
        <span>Java</span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiJavascript />
        <span>Javascript</span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiTypescript />
        <span>Typescript</span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPython />
        <span>Python</span>
      </Col>
      
      
      <Col xs={4} md={2} className="tech-icons">
        <SiCsharp />
        <span>C#</span>
      </Col>
      
    </Row>
  </>
  );
}

export default Program;
