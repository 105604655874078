import React from 'react'
import { Container } from "react-bootstrap";
import ContactForm from '../components/Contact/Contact';

const Contact = () => {
  return (
    <Container style={{padding: '60px'}} id="contact">
      
      <ContactForm />
    </Container>
  )
}

export default Contact