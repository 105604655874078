import React, { useState } from "react";
import { Container, Row, Col, ButtonGroup, Button } from "react-bootstrap";
import ProjectCard from "../components/Projects/ProjectCard";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

// Import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import projectDetails from "./projectDetails";

const Projects = () => {
  const [mode, setMode] = useState("all");

  const viewproject = (p) => {
    if (p.tech === mode) {
      return (
        <SwiperSlide key={p.key}>
          <ProjectCard
            imgPath={p.imgPath}
            isBlog={false}
            title={p.title}
            description={p.description}
            ghLink={p.ghLink}
            demoLink={p.demoLink}
            skills={p.skills}
          />
        </SwiperSlide>
      );
    } else if (mode === "all") {
      return (
        <SwiperSlide key={p.key}>
          <ProjectCard
            imgPath={p.imgPath}
            isBlog={false}
            title={p.title}
            description={p.description}
            ghLink={p.ghLink}
            demoLink={p.demoLink}
            skills={p.skills}
          />
        </SwiperSlide>
      );
    }
  };

  const handleFilter = (e) => {
    setMode(e.target.id);
  };

  return (
    <Container fluid className="project-section" id="projects">
      <Container>
        <h1 className="project-heading">
          Recent Top <strong className="yellow">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <ButtonGroup aria-label="Basic example">
          <Button
            variant="primary"
            style={{ width: "85px", margin: "1px" }}
            id="all"
            onClick={handleFilter}
          >
            All
          </Button>
          <Button
            variant="primary"
            style={{ width: "85px", margin: "1px" }}
            id="web"
            onClick={handleFilter}
          >
            Web
          </Button>
          <Button
            variant="primary"
            style={{ width: "85px", margin: "1px" }}
            id="games"
            onClick={handleFilter}
          >
            Games
          </Button>
        </ButtonGroup>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Swiper
            style={{ paddingTop: "40px", paddingBottom: "40px" }}
            modules={[Pagination]}
            spaceBetween={50}
            loop={true}
            pagination={{ clickable: true }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log("slide change")}
            breakpoints={{
              // When the screen width is >= 640px
              640: {
                slidesPerView: 1,
              },
              // When the screen width is >= 768px
              768: {
                slidesPerView: 2,
              },
              // When the screen width is >= 1024px
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            {projectDetails.map(viewproject)}
          </Swiper>
        </Row>
      </Container>
    </Container>
  );
};

export default Projects;
