import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiPostgresql,
  DiMysql
} from "react-icons/di";
import {
  SiMongodb,
} from "react-icons/si";



const Database = () => {
  return (
  <>
    
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      
      <Col xs={4} md={2} className="tech-icons">
        <DiMysql />
        <span>MySQL</span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiPostgresql />
        <span>PostgreSQL</span>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMongodb />
        <span>MongoDB</span>
      </Col>
      
      
    </Row>
  </>
  );
}

export default Database;
