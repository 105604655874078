import React from "react";
import { Container } from "react-bootstrap";


import Frontend from "../components/Skillset/Frontend";
import Backend from "../components/Skillset/Backend";
import Database from "../components/Skillset/Database";
import GameDev from "../components/Skillset/GameDev";
import Program from "../components/Skillset/Program";

const Skillset = () => {
  return (
    <Container fluid className="about-section" id="skillset">
      
      <Container>
        <h1 className="project-heading" style={{ paddingBottom: "50px" }}>
          Professional <strong className="yellow">Skillset </strong>
        </h1>
        <h3>
          Frontend <strong className="yellow">Technologies </strong>
        </h3>
        <Frontend />
        <h3>
          Backend <strong className="yellow">Technologies </strong>
        </h3>
        <Backend />
        <h3>
          Database <strong className="yellow">Management </strong>
        </h3>
        <Database />
        <h3>
          Game <strong className="yellow">Developement </strong>
        </h3>
        <GameDev />
        <h3>
          Programming <strong className="yellow">Languages </strong>
        </h3>
        <Program />

       

        

      </Container>
    </Container>
  )
}

export default Skillset