import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import LaptopImg from "../../assets/home-main.svg";
import Tilt from "react-parallax-tilt";

import { AiFillGithub } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaHackerrank } from "react-icons/fa";
import { FaStackOverflow } from "react-icons/fa";


const About = () => {
    return (
        <Container fluid className="home-about-section" id="about">
          <Container>
            <Row>
              <Col md={8} className="home-about-description" id="aboutme">
                <h1 style={{ fontSize: "2.6em" }}>
                  LET ME <b className="yellow"> INTRODUCE </b> MYSELF
                </h1>
                <p className="home-about-body">
                 Hi, my name is <b className="yellow">Anuhas Paranavithana </b>
                 and I'm from <b className="yellow"> Sri Lanka.</b>
                <br />
                <br />
                I am currently enrolled as an undergraduate student at the <b className="yellow">University of Moratuwa</b>, pursuing a Bachelor of Science (Honors) degree in <b className="yellow">Artificial Intelligence</b>.
                <br />
                <br />
                  As a
                  <b className="yellow"> dedicated undergraduate</b>,  
                  I enjoy tackling new challenges and continuously expanding my skillset.
                  <br />
                  <br />I am proficient in
                    <b className="yellow"> Javascript, </b>
                    as well as have knowledge in programming languages such as Python, C, C++, C#.
                  
                  <br />
                  <br />
                  I have a passion for crafting games
                  with <b className="yellow">Unity</b> and
                  building websites with
                    
                    <b className="yellow"> React Js</b> and
                    <b className="yellow"> Node Js</b>.
                  <br />
                  <br />
                  I am interested in building new
                  
                    <b className="yellow"> Web Sites</b> and <b className="yellow">games, </b>
                    as well as exploring areas related to
                    <b className="yellow"> Artificial Intelligence.</b>
                  
                  <br />
                  
                </p>
                
              </Col>
              <Col md={4} className="myAvtar">
                <Tilt>
                  <img src={LaptopImg} className="img-fluid" alt="avatar" />
                  
                </Tilt>
                <Row>
              <Col md={12} className="home-about-social">
                
                <ul className="home-about-social-links">
                <li className="social-icons">
                    <a
                      href="https://www.linkedin.com/in/anuhas-paranavithana-17a2b8213/"
                      target="_blank"
                      rel="noreferrer"
                      className="icon-colour  home-social-icons"
                      aria-label="linkedin"
                    >
                      <FaLinkedinIn />
                    </a>
                  </li>
                  <li className="social-icons">
                    <a
                      href="https://github.com/anuhasip"
                      target="_blank"
                      rel="noreferrer"
                      className="icon-colour  home-social-icons"
                      aria-label="github"
                    >
                      <AiFillGithub />
                    </a>
                  </li>
                  <li className="social-icons">
                    <a
                      href="https://www.instagram.com/anuhas_i_p/"
                      target="_blank"
                      rel="noreferrer"
                      className="icon-colour  home-social-icons"
                      aria-label="twitter"
                    >
                      <FaInstagram />
                    </a>
                  </li>
                  
                  <li className="social-icons">
                    <a
                      href="https://www.hackerrank.com/profile/anuhas_i_p"
                      target="_blank"
                      rel="noreferrer"
                      className="icon-colour home-social-icons"
                      aria-label="hackerrank"
                    >
                      <FaHackerrank />
                    </a>
                  </li>
                  <li className="social-icons">
                    <a
                      href="https://stackoverflow.com/users/19173248/anuhas"
                      target="_blank"
                      rel="noreferrer"
                      className="icon-colour home-social-icons"
                      aria-label="stackoverflow"
                    >
                      <FaStackOverflow />
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
            
              </Col>
            </Row>
            
          </Container>
        </Container>
      );
    
}

export default About